<div
  class="back-button"
>
  <button
    color="accent"
    mat-stroked-button
    (click)="goBack()"
  >
  {{ backText | translate }}
  </button>
</div>