<mat-accordion>
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title>
        {{ 'APP.LIST.FILTER.TITLE' | translate }}
      </mat-panel-title>
    </mat-expansion-panel-header>
    <ng-container>
      <ng-content>
      </ng-content>
    </ng-container>
  </mat-expansion-panel>
</mat-accordion>