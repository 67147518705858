<div mat-dialog-title class="dialog-title">
  <h4 class="dialog-title__title">
    {{ data.title | translate }}
  </h4>
  <button mat-icon-button mat-dialog-close>
      <mat-icon>close</mat-icon>
  </button>
</div>

<div mat-dialog-content>
  <p>
    {{ data.body | translate }}
  </p>
  <p *ngIf="data.subtitle">
    {{ data.subtitle | translate }}
  </p>
  <ul>
    <li *ngIf="data.option1">{{ data.option1 | translate }}</li>
    <li *ngIf="data.option2">{{ data.option2 | translate }}</li>
    <li *ngIf="data.option3">{{ data.option3 | translate }}</li>
    <li *ngIf="data.option4">{{ data.option4 | translate }}</li>
    <li *ngIf="data.option5">{{ data.option5 | translate }}</li>
  </ul>
</div>

<div mat-dialog-actions>
  <button
    *ngFor="let action of data.actions"
    mat-button
    [color]="action.color"
    [mat-dialog-close]="action.data">{{ action.label | translate }}</button>
  <button
    *ngIf="!data.actions?.length"
    mat-button
    color="accent"
    [mat-dialog-close]="true">
    {{ 'APP.SHARED.COMPONENTS.BASE_DIALOG.ACTIONS.OK' | translate }}</button>
  <button
    mat-button
    color="warn"
    mat-dialog-close>{{ 'APP.SHARED.COMPONENTS.BASE_DIALOG.ACTIONS.CANCEL' | translate }}</button>
</div>
